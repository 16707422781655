import { Link, useNavigate } from "react-router-dom";
import "./Navbar.css";

export default function Navbar(props) {
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate("/profile");
  };

  function clickHome() {
    if (window.location.pathname === "/" || window.location.pathname === "/nr")
      document.getElementById("home").scrollIntoView({ behavior: "smooth" });
    else window.location.href = "/";
  }

  function clickTracks() {
    if (window.location.pathname === "/" || window.location.pathname === "/nr")
      document.getElementById("tracks").scrollIntoView({ behavior: "smooth" });
    else window.location.href = "/";
  }

  function clickTimeline() {
    if (window.location.pathname === "/" || window.location.pathname === "/nr")
      document
        .getElementById("timeline")
        .scrollIntoView({ behavior: "smooth" });
    else window.location.href = "/";
  }

  function clickDiscord() {
    window.open("https://links.ieeevit.org/discord", "_blank");
  }

  return (
    <header className="header flex-container-row">
      <Link to="#" className="logo">
        <img
          className="navbar-logo"
          src="/images/Landing/Hero/IEEE.png"
          alt="logo"
        />
      </Link>

      <nav className="navbar">
        <div className="navbar-item" onClick={clickHome}>
          Home
        </div>
        <div className="navbar-item" onClick={clickTracks}>
          Tracks
        </div>
        <div className="navbar-item" onClick={clickTimeline}>
          Timeline
        </div>
        <div className="navbar-item" onClick={clickDiscord}>
          Discord
        </div>
      </nav>

      <div className="navbar-right-container flex-container-row">
        {props.user && props.user.avatar ? (
          <img
            className="navbar-profile-icon"
            src={props.user.avatar}
            alt="Profile"
            onClick={navigateToProfile}
          />
        ) : (
          <div>
            <button onClick={props.signIn} className="btn">
              LOG IN
            </button>
          </div>
        )}
        <div className="icons flex-item">
          {/* <img
            id="menu-btn"
            src="/images/Landing/menu-svgrepo-com.svg"
            alt="menu"
            className="menu"
          /> */}
        </div>
      </div>
    </header>
  );
}
