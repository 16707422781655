import { useEffect, useState } from "react";
import "./Team.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Popup from "../../components/Popup/Popup";
import Loading from "../Loading/Loading";

const userAPI = require("../../api/User");
const teamAPI = require("../../api/Team");

export default function Team() {
  const [user, setUser] = useState({});
  const [team, setTeam] = useState({});
  const [leader, setLeader] = useState({});
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const [r0Link, setR0link] = useState("");
  const [githubLink, setGithubLink] = useState("");

  const submitAPI = require("../../api/Submit");

  const navigate = useNavigate();

  function togglePopup() {
    setPopup(!popup);
  }

  function getName(username) {
    const name = username.split(" ");
    name.pop();
    return name.join(" ");
  }

  function submitR0() {
    const ans = window.confirm(
      "Are you sure you want to submit? Make sure the PPT link is public and accessible to everyone."
    );

    if (!ans) {
      return;
    }

    setLoading(true);
    submitAPI.submitR0(r0Link).then((response) => {
      if (response.popup) {
        setPopupContent(response.popup);
        setPopup(true);
      } else {
        teamAPI.getTeam().then((response) => {
          if (response.unauthorized === undefined) {
            setTeam(response.team);
            setLeader(response.leader);
            setMembers(response.members);
            setLoading(false);
          } else {
            navigate("/");
          }
        });
        navigate("/team");
      }
      setLoading(false);
    });
  }

  function submitGithub() {
    const ans = window.confirm(
      "Are you sure you want to submit? Make sure the Github link is public and accessible to everyone."
    );

    if (!ans) {
      return;
    }

    setLoading(true);

    submitAPI.submitGithub(githubLink).then((response) => {
      if (response.popup) {
        setPopupContent(response.popup);
        setPopup(true);
      } else {
        teamAPI.getTeam().then((response) => {
          if (response.unauthorized === undefined) {
            setTeam(response.team);
            setLeader(response.leader);
            setMembers(response.members);
            setLoading(false);
          } else {
            navigate("/");
          }
        });
        navigate("/team");
      }
      setLoading(false);
    });
  }

  function handleR0linkChange(event) {
    setR0link(event.target.value);

    if (event.target.value.trim().length > 0) {
      document.getElementById("r0-submit").style.display = "block";
    } else {
      document.getElementById("r0-submit").style.display = "none";
    }
  }

  function handleGithubLinkChange(event) {
    setGithubLink(event.target.value);

    if (event.target.value.trim().length > 0) {
      document.getElementById("r2-submit").style.display = "block";
    } else {
      document.getElementById("r2-submit").style.display = "none";
    }
  }

  function leaveTeam() {
    const ans = window.confirm("Are you sure you want to leave the team?");
    if (ans) {
      setLoading(true);
      teamAPI.leaveTeam().then((response) => {
        if (response.popup) {
          setPopupContent(response.popup);
          setPopup(true);
        } else {
          navigate("/");
        }
        setLoading(false);
      });
    }
  }

  function openR1Review() {
    setPopupContent(team.suggestion);
    setPopup(true);
  }

  useEffect(() => {
    document.title = "Team | Prudentia";

    userAPI.getMe().then((response) => {
      if (response.unauthorized === undefined) {
        setUser(response.user);
        if (response.team === null) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    });

    teamAPI.getTeam().then((response) => {
      if (response.unauthorized === undefined) {
        setTeam(response.team);
        setLeader(response.leader);
        setMembers(response.members);
        setLoading(false);
      } else {
        navigate("/");
      }
    });
  }, [navigate]);

  return loading ? (
    <Loading />
  ) : (
    <div className="team-profile-page">
      {popup ? (
        <Popup togglePopup={togglePopup} content={popupContent} />
      ) : null}
      <Navbar user={user} />
      <div className="header-section flex-container-column">
        <h1 className="team-name flex-item">{team.team_name}</h1>
        <h2 className="team-code flex-item">TEAM CODE: {team.team_code}</h2>
      </div>
      <div className="member-section flex-container-column">
        <h2 className="team-members-header">TEAM MEMBERS</h2>
        <div className="team-leader-container flex-container-row">
          <div className="team-member flex-item">
            {getName(leader.username)}
          </div>
          <p className="team-leader flex-item">Team Leader</p>
        </div>
        {members.map((member) => (
          <div className="team-member flex-item">
            {getName(member.username)}
          </div>
        ))}
      </div>
      <div className="leave-section flex-container-column">
        <div className="leave-button flex-item">
          <button onClick={leaveTeam} className="leave-team flex-item">
            Leave Team
          </button>
        </div>
      </div>
      <div className="input-section flex-container-column">
        <div className="input-container flex-item flex-container-row">
          <img
            className="input-image flex-item"
            src="/images/Team/ppt.svg"
            alt="ppt logo"
          />
          {!team.r0_ppt_link ? (
            <input
              type="text"
              onChange={handleR0linkChange}
              value={r0Link}
              id="r0"
              className="submit-input-box flex-item"
              placeholder="PPT Link"
            ></input>
          ) : (
            <div
              className="r0-link-view flex-item"
              onClick={() => window.open(`${team.r0_ppt_link}`, "_blank")}
            >
              Idea Submission Round PPT
            </div>
          )}
          <img
            className="upload-link-image input-image flex-item"
            src="/images/Team/upload.png"
            alt="Submit"
            id="r0-submit"
            onClick={submitR0}
          />
        </div>
        {team.suggestion ? (
          <div className="input-container flex-item flex-container-row">
            <img
              className="input-image flex-item"
              src="/images/Team/idea.png"
              alt="Idea"
            />
            <div className="r0-link-view flex-item" onClick={openR1Review}>
              Review One Suggestions
            </div>
          </div>
        ) : null}

        {team.r0_ppt_link ? (
          <div className="input-container flex-item flex-container-row">
            <img
              className="input-image flex-item"
              src="/images/Team/git.svg"
              alt="git logo"
            />
            {!team.r2_github_link ? (
              <input
                value={githubLink}
                onChange={handleGithubLinkChange}
                type="text"
                className="submit-input-box flex-item"
                placeholder="Github Link"
              />
            ) : (
              <div
                className="r0-link-view flex-item"
                onClick={() => window.open(`${team.r2_github_link}`, "_blank")}
              >
                Github Repository Link
              </div>
            )}
            {!team.r2_github_link ? (
              <img
                className="upload-link-image input-image flex-item"
                src="/images/Team/upload.png"
                alt="Submit"
                id="r2-submit"
                onClick={submitGithub}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}
