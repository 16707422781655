import "./Popup.css";

export default function Popup(props) {
  return (
    <div onClick={props.togglePopup} className="popup-page">
      <div class="popup-wrapper flex-container-row">
        <div class="popup flex-container-column flex-item">
          <div class="popup-content flex-item">{props.content}</div>
          <img
            id="cross"
            class="popup-cross flex-item"
            src="/images/Popup/cross.svg"
            alt="cross"
            onClick={props.closePopup}
          />
        </div>
      </div>
    </div>
  );
}
