import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Cookies from "universal-cookie";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";

const cookies = new Cookies();
const userAPI = require("../../api/User");

export default function Profile() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [teamName, setTeamName] = useState("No Team");

  function logOut() {
    cookies.remove("token");
    cookies.remove("user");
    navigate("/");
  }

  useEffect(() => {
    document.title = "Profile | Prudentia";
    if (cookies.get("token")) {
      userAPI.getMe().then((response) => {
        if (response.unauthorized === undefined) {
          setUser(response.user);
          if (response.team) setTeamName(response.team.team_name);
          setLoading(false);
        } else {
          cookies.remove("token");
          cookies.remove("user");
          setLoading(false);
          navigate("/");
        }
      });
    } else {
      setLoading(false);
      navigate("/");
    }
  }, [navigate]);

  return loading ? (
    <Loading />
  ) : (
    <div className="profile-page flex-container-column">
      <Navbar user={user} />
      <div class="profile-details-container flex-container-row">
        <img
          alt="profile pic"
          src={user.avatar ? user.avatar : "/images/Profile/img 2.png"}
          class="logo-img flex-item"
        />
        <div class="profile-details flex-container-column flex-item">
          <div class="profile-username flex-container-column flex-item">
            <h1>Username</h1>
            <div type="text" class="text-inputuser">
              {user.username}
            </div>
          </div>
          <div class="profile-email flex-container-column flex-item">
            <h1>Email:</h1>
            <div type="text" class="text-inputmail">
              {user.email}
            </div>
          </div>
          <div class="profile-team-name flex-container-column flex-item">
            <h1>Team Name:</h1>
            <div type="text" class="text-inputname">
              {teamName}
            </div>
          </div>
        </div>
      </div>
      <div onClick={logOut} class="profile-logout flex-item">
        Logout
      </div>
    </div>
  );
}
