import instance from "./init";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getTeam = async () => {
  return await instance
    .get("/team", {
      headers: {
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        return { unauthorized: true };
      }
    });
};

export const createTeam = async (teamName) => {
  return await instance
    .post(
      "/team/create",
      {
        team_name: teamName,
      },
      {
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        return { unauthorized: true };
      }
    });
};

export const joinTeam = async (teamCode) => {
  return await instance
    .post(
      `/team/join/${teamCode}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        return { unauthorized: true };
      }
    });
};

export const leaveTeam = async () => {
  return await instance
    .post(
      "/team/leave",
      {},
      {
        headers: {
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        return { unauthorized: true };
      }
    });
};
