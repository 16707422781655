import instance from "./init";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getMe = async () => {
  return await instance
    .get("/user", {
      headers: {
        Authorization: `Bearer ${cookies.get("token")}`,
      },
    })
    .then((response) => {
      if (response.data.user)
        cookies.set("user", response.data.user, { path: "/" });
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        cookies.remove("user", { path: "/" });
        return { unauthorized: true };
      }
    });
};
