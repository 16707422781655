import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import "./NRegistered.css";
import Popup from "../../components/Popup/Popup";
import Navbar from "../../components/Navbar/Navbar";
import Loading from "../Loading/Loading";
import data from "../../data/tracks.json";

const userAPI = require("../../api/User");
const cookies = new Cookies();

export default function NRegister() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const [popupContent, setPopupContent] = useState(
    "YOU ARE NOT REGISTERED ON VTOP. IF YOU THINK THIS IS WRONG OPEN A TICKET ON DISCORD"
  );

  const navigate = useNavigate();

  function showDigitalSafety() {
    setPopupContent(data.digitalSafety);
    togglePopup();
  }

  function showFinTech() {
    setPopupContent(data.finTech);
    togglePopup();
  }

  function showHealthTech() {
    setPopupContent(data.healthTech);
    togglePopup();
  }

  function showAgriTech() {
    setPopupContent(data.agriTech);
    togglePopup();
  }

  function showEdTech() {
    setPopupContent(data.edTech);
    togglePopup();
  }

  function showOpenInnovation() {
    setPopupContent(data.openInnovation);
    togglePopup();
  }

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const signInWithGoogle = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
    window.open(url, "_self");
  };

  const navigateToCreate = () => {
    navigate("/create");
  };

  const navigateToJoin = () => {
    navigate("/join");
  };

  useEffect(() => {
    setPopupContent(
      "YOU ARE NOT REGISTERED ON VTOP. IF YOU THINK THIS IS WRONG OPEN A TICKET ON DISCORD"
    );
    if (cookies.get("token")) {
      userAPI.getMe().then((response) => {
        if (response.unauthorized === undefined) {
          setUser(response.user);
          setLoading(false);
        } else {
          cookies.remove("token");
          cookies.remove("user");
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }

    // countdown timer
    const countDownDate = new Date("Mar 21, 2024 19:00:00").getTime();

    const x = setInterval(function () {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (
        document.getElementById("hour-data") !== null &&
        document.getElementById("min-data") !== null &&
        document.getElementById("sec-data") !== null
      ) {
        document.getElementById("hour-data").innerHTML = hours;
        document.getElementById("min-data").innerHTML = minutes;
        document.getElementById("sec-data").innerHTML = seconds;
      }

      if (distance < 0) {
        clearInterval(x);
        document.getElementById("hour-data").innerHTML = "00";
        document.getElementById("min-data").innerHTML = "00";
        document.getElementById("sec-data").innerHTML = "00";
      }
    }, 1000);

    const navbar = document.querySelector(".navbar");

    if (document.getElementById("menu-btn"))
      document.querySelector("#menu-btn").onclick = () => {
        navbar.classList.toggle("active");
      };
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div id="home" name="home" className="landing-page">
      {showPopup ? (
        <Popup togglePopup={togglePopup} content={popupContent} />
      ) : null}
      <Navbar signIn={signInWithGoogle} user={user} />
      <section className="hero flex-container-column">
        <div className="bgholder">
          <img src="/images/Landing/Hero/logo.svg" alt="Ring bg" />
        </div>
        {!user.email ? (
          <button
            onClick={signInWithGoogle}
            className="hero-section-button btn"
          >
            LOG IN
          </button>
        ) : user.team_code === null ? (
          <div className="hero-button-container flex-container-row">
            <div
              onClick={navigateToCreate}
              className="hero-section-button btn row-button"
            >
              CREATE TEAM
            </div>
            <div
              onClick={navigateToJoin}
              className="hero-section-button btn row-button"
            >
              JOIN TEAM
            </div>
          </div>
        ) : (
          <button className="hero-section-button btn">TEAM PORTAL</button>
        )}
      </section>

      <section className="updates flex-container-column">
        <h2 className="flex-item">HACK STARTS IN</h2>
        <div className="timer flex-item flex-container-column">
          <div className="hours flex-item flex-container-row">
            <span id="hour-data">00</span>Hours
          </div>
          <div className="minutes flex-item flex-container-row">
            <span id="min-data">00</span>minutes
          </div>
          <div className="seconds flex-item flex-container-row">
            <span id="sec-data">00</span>seconds
          </div>
        </div>
      </section>

      <section className="sponsor flex-container-column">
        <h2>MEET OUR SPONSORS</h2>
        <div className="flex-item flex-container-column">
          <img
            className="flex-item"
            src="/images/Landing/Space to grow logo.svg"
            alt=""
          />
        </div>
      </section>

      <section
        id="tracks"
        name="tracks"
        className="tracks flex-container-column"
      >
        <h2>Tracks</h2>
        <div className="tracks-wrapper flex-container-column">
          <div className="tracks-container flex-container-row">
            <div
              onClick={showDigitalSafety}
              className="track flex-container-column"
            >
              <h3>
                Digital Safety <br></br>(Powered by Space2Grow)
              </h3>
            </div>
            <div onClick={showFinTech} className="track flex-container-column">
              <h3>FinTech</h3>
            </div>
            <div
              onClick={showHealthTech}
              className="track flex-container-column"
            >
              <h3>HealthTech</h3>
            </div>
            <div onClick={showAgriTech} className="track flex-container-column">
              <h3>AgriTech</h3>
            </div>
            <div onClick={showEdTech} className="track flex-container-column">
              <h3>EdTech</h3>
            </div>
            <div
              onClick={showOpenInnovation}
              className="track flex-container-column"
            >
              <h3>Open Innovation</h3>
            </div>
          </div>
        </div>
      </section>

      <section id="timeline" name="timeline" className="flex-container-column">
        <h2>ROUNDS</h2>
        <div className="flex-container-row rounds-wrapper">
          <div className="flex-item rounds-left flex-container-column">
            <div className="flex-container-row rounds">
              <div className="flex-container-row">
                <img
                  className="logo"
                  src="/images/Landing/Rounds/idea.svg"
                  alt=""
                />
                <h3>ROUND 1</h3>
              </div>
              <img
                className="node"
                src="/images/Landing/Rounds/Node_left.svg"
                alt=""
              />
            </div>
            <div className="flex-container-row rounds">
              <div className="flex-container-row">
                <img
                  className="logo"
                  src="/images/Landing/Rounds/Dev.svg"
                  alt=""
                />
                <h3>ROUND 3</h3>
              </div>
              <img
                className="node"
                src="/images/Landing/Rounds/Node_left.svg"
                alt=""
              />
            </div>
          </div>
          <div className="flex-item rounds-right flex-container-column">
            <div className="rounds flex-container-row">
              <img
                className="node"
                src="/images/Landing/Rounds/Node.svg"
                alt=""
              />
              <div className="flex-container-row">
                <h3>ROUND 0</h3>
                <img
                  className="logo"
                  src="/images/Landing/Rounds/Submission.svg"
                  alt="Submission logo"
                />
              </div>
            </div>
            <div className="rounds flex-container-row">
              <img
                className="node"
                src="/images/Landing/Rounds/Node.svg"
                alt=""
              />
              <div className="flex-container-row">
                <h3>ROUND 2</h3>
                <img
                  className="logo"
                  src="/images/Landing/Rounds/GD.svg"
                  alt="GD logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer flex-container-column">
        <h2 flex-item>CONTACT US</h2>
        <div className="contact flex-item flex-container-row">
          <div
            onClick={() => window.open("mailto:contact@ieeevit.org", "_blank")}
          >
            <img
              className="footer-icon-link"
              src="/images/Landing/social/mail.svg"
              alt="Email"
            />
          </div>
          <div
            onClick={() =>
              window.open("https://www.instagram.com/ieeevitvellore/", "_blank")
            }
          >
            <img
              className="footer-icon-link"
              src="/images/Landing/social/instagram.svg"
              alt="instagram"
            />
          </div>
          <div
            onClick={() =>
              window.open("https://twitter.com/ieeevitvellore", "_blank")
            }
          >
            <img
              className="footer-icon-link"
              src="/images/Landing/social/x.svg"
              alt="X"
            />
          </div>
          <div
            onClick={() =>
              window.open("https://www.youtube.com/@IEEEVITVellore", "_blank")
            }
          >
            <img
              className="footer-icon-link"
              src="/images/Landing/social/youtube.svg"
              alt="Youtube"
            />
          </div>
          <div
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/ieee-vit-vellore",
                "_blank"
              )
            }
          >
            <img
              className="footer-icon-link"
              src="/images/Landing/social/Linkedin.svg"
              alt="Linkedin"
            />
          </div>
          <div
            onClick={() => window.open("https://github.com/IEEE-VIT", "_blank")}
          >
            <img
              className="footer-icon-link"
              src="/images/Landing/social/github.svg"
              alt="Github"
            />
          </div>
        </div>
        <div className="flex-item ieee">
          <img
            className="footer-icon-link"
            src="/images/Landing/social/ieee2.svg"
            alt=""
          />
        </div>
      </footer>
    </div>
  );
}
