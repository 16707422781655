import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Create.css";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import Loading from "../Loading/Loading";

const teamAPI = require("../../api/Team");
const userAPI = require("../../api/User");

export default function Create() {
  const [teamName, setTeamName] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const navigate = useNavigate();

  function handleTeamNameChange(event) {
    setTeamName(event.target.value);
  }

  function togglePopup() {
    setPopup(!popup);
  }

  const createTeam = () => {
    setLoading(true);
    teamAPI.createTeam(teamName).then((response) => {
      if (response.popup) {
        setPopupContent(response.popup);
        setPopup(true);
      } else {
        navigate("/team");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = "Create Team | Prudentia";
    userAPI.getMe().then((response) => {
      if (response.unauthorized === undefined) {
        setUser(response.user);
        setLoading(false);
      } else {
        navigate("/");
      }
    });
  }, [navigate]);

  return loading ? (
    <Loading />
  ) : (
    <div className="create-page flex-container-column">
      {popup ? (
        <Popup togglePopup={togglePopup} content={popupContent} />
      ) : null}
      <Navbar user={user} />
      <div className="create-page-container flex-container-column">
        <div className="header-section flex-container-column">
          <h1 className="join-team flex-item">CREATE TEAM</h1>
          <h2 className="enter-team flex-item">ENTER THE TEAM NAME</h2>
        </div>
        <div className="user-section flex-container-column">
          <input
            type="text"
            value={teamName}
            onChange={handleTeamNameChange}
            className="input-box flex-item"
          />
        </div>
        <div className="submit-section flex-container-column">
          <button onClick={createTeam} className="button flex-item">
            CREATE TEAM
          </button>
        </div>
      </div>
    </div>
  );
}
