import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";

const userAPI = require("../../api/User");

export default function Token() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Loading... | Prudentia";
    const cookies = new Cookies();
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const googleAuth = params.get("token");
    cookies.set("token", googleAuth, { path: "/" });
    userAPI.getMe().then((response) => {
      navigate("/");
    });
  }, [navigate]);

  return <Loading />;
}
