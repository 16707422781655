import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Landing from "./pages/Landing Page/Landing";
import Create from "./pages/Create Team/Create";
import Join from "./pages/Join Team/Join";
import Profile from "./pages/User Profile/Profile";
import Team from "./pages/Team Section/Team";
import Token from "./pages/Token/Token";
import NRegister from "./pages/Not Registered/NRegistered";
import Loading from "./pages/Loading/Loading";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/googleToken" element={<Token />} />
        <Route path="/" element={<Landing />} />
        <Route path="/nr" element={<NRegister />} />
        <Route path="/create" element={<Create />} />
        <Route path="/join" element={<Join />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/team" element={<Team />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="*" element={<Landing />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
