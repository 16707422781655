import "./Loading.css";

export default function Loading() {
  return (
    <div className="loading-page flex-container-column">
      <img
        className="loading-logo flex-item"
        src="/images/Loading/loader.gif"
        alt="loading"
      />
      <h1 className="loading-text flex-item">
        We don't have a loading page because our designers were busy.
      </h1>
    </div>
  );
}
